import React, { useState, useRef, useEffect } from "react"
import { useLocation } from '@reach/router'
import { navigate } from "gatsby"

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"

import PAYMENT_API from "../api/payment"
import EVENTS_API from "../api/events"

import useScript from '../service/useScript'
import Loader from "../components/addon/Loader"

import { GATSBY_BILLDESK_URL } from '../service/config'

const Payment = () => {

    const location = useLocation()
    const metaTags = [];
    const [msg, setMsg] = useState(null)
    //const [callbackUrl, setCallbackUrl] = useState(null)
    const [newRes, setNewRes] = useState(null)
    const refClick = useRef(null);
    const [paras, setParas] = useState(null)

    const payChecksum = () => {
        PAYMENT_API.decodeSHA(msg.response_token)
        .then(res => {
            console.log('asasasass==',res)
            if(res && res?.decoded_data && res?.decoded_data?.payload && res?.decoded_data?.payload?.links){
                setParas(res?.decoded_data?.payload?.links[1]?.parameters)
                triggerSubmit(res)
            }
            console.log('asasasass==',paras, paras?.bdorderid, paras?.mercid, paras?.rdata)
        })
        .catch(err => {
            console.log(err)
        })
    }

    const eventPaymentCheckSum = () => {
        let payload = { msg: msg }
        EVENTS_API.eventChecksum(payload)
        .then(res => {
            setNewRes(res)
            triggerSubmit(res)
            //paymentInitialize(res)
        })
        .catch(err => {
            console.log(err)
        })
    }

    const triggerSubmit = (res) => {
        setTimeout(() => { 
            refClick.current.click()
        }, 100);
    }
    useEffect(() => {
        if(msg) {
            if(location.state?.events) {
                eventPaymentCheckSum()
            } else {
                payChecksum()
            }
        }
    },[msg])
    
    useEffect(() => {
        if(location && window && location?.state !== null) {
            if(location.state?.msg) {
                setMsg(location.state?.msg)
            }
            //if(location.state?.callback) setCallbackUrl(location.state?.callback)
            if(!location.state?.msg) {
                navigate('/')
            }
        } else {
            navigate('/')
        }
    },[location])
    
    return (
        <Layout>
            <Meta
                title="payment"
                files={{
                    js: [],
                    css: ["/assets/css/login.css" ,"/assets/css/accessibility.css"]
                }}
                tags={metaTags}
            />
            <main className="innerpage">
                {/* https://uat1.billdesk.com/u2/web/v1_2/embeddedsdk */}
                <form name="sdklaunch" id="sdklaunch" action="https://pay.billdesk.com/web/v1_2/embeddedsdk" method="POST">
                    <input type="hidden" id="bdorderid" name="bdorderid" value={paras?.bdorderid} />
                    <input type="hidden" id="merchantid" name="merchantid" value={paras?.mercid} />
                    <input type="hidden" id="rdata" name="rdata" value={paras?.rdata} />
                    <input className="invisible" name='submit' type='submit' ref={refClick}  value='Complete your Payment' />
                </form>

                {/* <form method='POST' action='https://pgi.billdesk.com/pgidsk/PGIMerchantPayment'>
                    <input type='hidden' name='msg' value={`${msg}|${newRes}`} />
                    <input className="invisible" type='Submit' ref={refClick} />
                </form> */}
            </main>
            <section className="section_bg login_section pt-first-section">
                <Loader loadingMessage={`Please wait...`} />
            </section>
        </Layout>
    )
}

export default Payment